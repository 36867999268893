import * as React from "react";
import Layout from "../../components/Layout";
import Section from "../../components/Layout/Section";
import Banner from "../../components/Banner";
import BreadCrumbBlock from "../../components/Breadcrumb";
import { Container, Form, Pagination, Row } from "react-bootstrap";
import Title from "../../components/Layout/Title";
import Select from "react-select";
import Icon from "../../images/icons.svg";
import BlogItem from "../../components/BlogItem";

const customStyles = {
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? 0 : 0,
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: state.isFocused ? 0 : 0,
    },
  }),
  indicatorSeparator: () => ({ display: "none" }),
  menu: (base) => ({
    ...base,
    width: "max-content",
    zIndex: 10,
  }),
  container: (provided) => ({
    ...provided,
    minWidth: 125,
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    background: isFocused
      ? "rgba(0, 0, 0, 0.5)"
      : isSelected
      ? "rgba(0, 0, 0, 1)"
      : "rgba(0, 0, 0, 0)",
    color: isFocused ? "#ffffff" : isSelected ? "#ffffff" : undefined,
  }),
};

const options = [
  { value: "all_month", label: "All Months" },
  { value: "1_month", label: "1 Month" },
  { value: "2_month", label: "2 Months" },
];

// markup
const BlogPage = () => {
  return (
    <Layout>
      <Banner title="Blogs" />
      <BreadCrumbBlock />
      <Section noSpacingTop>
        <Container>
          <Title
            subTitle="Our latest"
            title="Explore our latest articles"
            className="text-center"
          />
          <div className="blog-button-group">
            <a href="#" className="btn btn-outline-primary active">
              All
            </a>
            <a href="#" className="btn btn-outline-primary">
              Media
            </a>
            <a href="#" className="btn btn-outline-primary">
              News
            </a>
            <a href="#" className="btn btn-outline-primary">
              Resources
            </a>
            <a href="#" className="btn btn-outline-primary">
              Sport
            </a>
          </div>
          <div className="filter mt-1">
            <div className="filter-search">
              <Form>
                <Form.Group className="form-group" controlId="BLogSearch">
                  <Form.Control size="sm" type="text" placeholder="Search" />
                </Form.Group>
                <button className="btn-reset search-submit">
                  <svg width="20" height="20">
                    <use xlinkHref={`${Icon}#icon-search`}></use>
                  </svg>
                </button>
              </Form>
            </div>
            <div className="filter-select">
              <p className="h5">Filter by Month</p>
              <Select
                defaultValue={{ value: "all_month", label: "All Months" }}
                isSearchable={false}
                styles={customStyles}
                options={options}
              />
            </div>
          </div>

          <div className="blog-list">
            <Row>
              <BlogItem />
              <BlogItem />
              <BlogItem />
              <BlogItem />
            </Row>
          </div>

          <div className="pagination-wrap">
            <Pagination className="justify-content-center">
              <Pagination.Prev />
              <Pagination.Item active>{1}</Pagination.Item>
              <Pagination.Item>{2}</Pagination.Item>
              <Pagination.Item>{3}</Pagination.Item>
              <Pagination.Item>{4}</Pagination.Item>
              <Pagination.Item>{5}</Pagination.Item>
              <Pagination.Ellipsis className="dots" />
              <Pagination.Item>{12}</Pagination.Item>
              <Pagination.Next />
            </Pagination>
            <p className="text-sm text-secondary">Show 10 of 20</p>
          </div>
        </Container>
      </Section>
    </Layout>
  );
};

export default BlogPage;
