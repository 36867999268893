import React from "react";
import DefaultImage from "../../images/threeimg-1.png";
import { Col, Row } from "react-bootstrap";

const BlogItem = () => {
  return (
    <Col xs={6} lg={12}>
      <div className="blog-item">
        <Row>
          <Col xs={12} lg={5} xl={4}>
            <div className="blog-image">
              <img src={DefaultImage} alt="" />
            </div>
          </Col>
          <Col xs={12} lg={7} xl={8}>
            <div className="blog-desc">
              <div className="top">
                <div className="span-group">
                  <span className="text-sm">Media</span>
                  <span className="text-sm">09 December 2021</span>
                </div>
                <a href="#" className="h3">
                  Lorem ipsum dolor sit amet 9
                </a>
                <p>
                  Pellentesque eu commodo risus, a rutrum nisl. Praesent
                  bibendum sit amet ex sit amet tincidunt. Curabitur eget
                  vestibulum dui, et placerat ante. Vestibulum ante ipsum primis
                  in faucibus orci luctus et ultrices posuere cubilia curae;
                  Cras accumsan, dui quis tristique lobortis, turpis ante
                  elementum sapien, in scelerisque velit nulla quis sapie
                </p>
              </div>
              <div className="bot">
                <a href="#" className="cta">
                  Read More
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Col>
  );
};

export default BlogItem;
